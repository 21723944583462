<template>
  <div>
    <div class="pagination md:w-full md:flex-row justify-center flex">
      <ul v-if="pager.pages && pager.pages.length" class="pagination flex md:flex-row justify-around">
        <li class="page-p-item first" :class="{ disabled: pager.currentPage === 1 }">
          <a class="page-link" @click="setPage(1)" :style="defaultStyles.a">{{ defaultLabels.first }}</a>
        </li>
        <li class="page-p-item previous" :class="{ disabled: pager.currentPage === 1 }">
          <a class="page-link" @click="setPage(pager.currentPage - 1)" :style="defaultStyles.a">{{ defaultLabels.previous }}</a>
        </li>
        <li v-for="page in pager.pages" :key="page" class="page-p-item page-number" :class="{ active: pager.currentPage === page }">
          <a class="page-link" @click="setPage(page)" :style="defaultStyles.a">{{ page }}</a>
        </li>
        <li class="page-p-item next" :class="{ disabled: pager.currentPage === pager.totalPages }">
          <a class="page-link" @click="setPage(pager.currentPage + 1)" :style="defaultStyles.a">{{ defaultLabels.next }}</a>
        </li>
        <li class="page-p-item last" :class="{ disabled: pager.currentPage === pager.totalPages }">
          <a class="page-link" @click="setPage(pager.totalPages)" :style="defaultStyles.a">{{ defaultLabels.last }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "Pagination",
  props: {
    currentPage: [String, Number],
    totalPages: [String, Number],
  },
  emits: ["page"],
  setup(props, { emit }) {
    const paginate = (totalPages, currentPage = 1, maxPages = 3) => {
      // ensure current page isn't out of range
      if (currentPage < 1) {
        currentPage = 1;
      } else if (currentPage > totalPages) {
        currentPage = totalPages;
      }

      let startPage, endPage;
      if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);

      // return object with all pager properties required by the view
      return {
        currentPage: currentPage,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        pages: pages,
      };
    };

    const defaultLabels = {
      first: "<<",
      last: ">>",
      previous: "<",
      next: ">",
    };

    const defaultStyles = {
      ul: {
        margin: 0,
        padding: 0,
        display: "inline-block",
      },
      li: {
        listStyle: "none",
        display: "inline",
        textAlign: "center",
      },
      a: {
        cursor: "pointer",
        padding: "6px 12px",
        display: "block",
        float: "left",
      },
    };

    const pager = computed(() => {
      return paginate(props.totalPages, props.currentPage);
    });

    const setPage = (p) => {
      emit("page", p);
    };

    return {
      pager,
      defaultLabels,
      defaultStyles,
      setPage,
    };
  },
};
</script>

<style lang="pcss">
.page-p-item {
  @apply text-center inline list-none px-0.5 md:px-1 bg-white rounded-md mx-1 md:mx-2 text-sm mb-0.5 text-ch1blue font-brother border border-ch1blue;
}

.page-p-item.first, .page-p-item.last, .page-p-item.previous, .page-p-item.next {
  @apply bg-white px-0;
}

.page-p-item.disabled {
  @apply text-gray-400 bg-gray-200 border-0;
}

.page-p-item.active {
  @apply font-bold bg-ch1blue text-white;
}
</style>
