<script setup>
import store from "@/store";
import router from "@/router";
import { computed, onMounted, reactive, ref, defineProps, watch } from "vue";
import Pagination from "@/components/Pagination.vue";
import ImageKit from "@/components/ImageKit.vue";
import Fixed from "@/components/Fixed.vue";
import Overlay from "@/components/Overlay.vue";
import Navigation from "../components/Navigation.vue";
import GalleryFilter from "../components/Gallery/GalleryFilter.vue";
import NftDetailModal from "@/components/NftDetailModal.vue";
import NftListCard from "@/components/NftListCard.vue";
import LoaderComp from "@/components/LoaderComp.vue";
import NavigationNew from "@/components/NavigationNew.vue";

const props = defineProps({
  wallet: String,
});

const loading = ref(false);

const refreshData = async () => {
  loading.value = true;
  userView.value = await store.dispatch("users/getTokens", props.wallet);
  loading.value = false;
};

watch(props, (newVal, oldVal) => {
  selectNft(null);
  refreshData();
});

const isLoggedIn = computed(() => {
  return store.getters["auth/isLoggedIn"];
});

const user = computed(() => {
  return store.state.auth.user;
});

const tokens = ref([]);

const userView = ref(null);

onMounted(async () => {
  await store.dispatch("auth/getUser");
  await refreshData();
});

const selectedNft = ref(null);
const selectNft = (token) => {
  selectedNft.value = token;
};

const calcPercent = (number) => {
  return (Math.round(number * 10000) / 100).toFixed(2);
};
</script>

<template>
  <div>
    <NavigationNew />
    <div class="bg-repeat h-full pt-8 relative min-h-screen">
      <div class="pb-12 pt-4 font-brother font-bold text-xl text-ch1blue text-center px-12 truncate">{{ userView?.username ?? props.wallet }}</div>
      <div class="flex relative">
        <div class="w-full md:w-auto px-4 pb-12">
          <div class="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 gap-6">
            <NftListCard v-for="token in userView?.tokens" :key="token.id" @click="selectNft(token)" :token="token" />
          </div>
          <NftDetailModal
            v-if="selectedNft"
            :token="selectedNft"
            @close="
              () => {
                selectNft(null);
              }
            "
          />
        </div>
      </div>
    </div>
    <LoaderComp v-if="loading" />
  </div>
</template>

<style lang="pcss"></style>
