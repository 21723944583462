<script setup>
import GalleryFilter from "./GalleryFilter.vue";
import { defineProps, defineEmits, ref, computed } from "vue";

const props = defineProps({
  name: String,
  filters: Array,
});

const emit = defineEmits(["select"]);

const selectEmit = (key, value) => {
  emit("select", key, value);
};

const isOpen = ref(false);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

const sortedFilters = computed(() => {
  const filterCopy = [...props.filters];
  return filterCopy?.sort((a, b) => (a.value > b.value ? 1 : -1));
});
</script>

<template>
  <div class="mb-4">
    <div @click="toggleOpen" class="cursor-pointer flex items-end justify-between">
      <div class="font-brother text-2xl text-ch1blue font-bold">{{ props.name }}</div>
      <div class="uppercase text-xs font-brother text-gray-400 font-bold">
        {{ isOpen ? "hide" : "show" }}
      </div>
    </div>
    <div v-if="isOpen" class="mt-2">
      <GalleryFilter
        v-for="(filterSection, f) in props.filters"
        :key="f"
        :name="filterSection.name"
        :filters="filterSection.filters"
        @select="selectEmit"
        :count="filterSection.count"
      ></GalleryFilter>
    </div>
  </div>
</template>
