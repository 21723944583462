<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";

const props = defineProps({
  name: String,
  open: Boolean,
  filters: Array,
  count: Number,
});

const emit = defineEmits(["toggle", "select"]);

const toggleEmit = () => {
  emit("toggle");
};

const selectEmit = (filterVal) => {
  emit("select", props.name, filterVal);
};

const isOpen = ref(false);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

const sortedFilters = computed(() => {
  const filterCopy = [...props.filters];
  return filterCopy?.sort((a, b) => (a.value > b.value ? 1 : -1));
});
</script>

<template>
  <div class="mb-2 border-b border-b-gray-300">
    <div @click="toggleOpen" class="cursor-pointer flex items-center justify-between px-2">
      <div class="font-bold text-sm text-black py-3 font-brother">{{ props.name }}</div>
      <div class="flex items-center">
        <div v-if="props.count" class="font-bold text-white rounded-full w-5 h-5 mr-3 font-sans text-xs bg-ch1blue flex items-center justify-center">{{ props.count }}</div>
        <div class="mr-2" v-if="!isOpen"><img :src="require('@/assets/icons/arrow-down.svg')" style="height: 5px" /></div>
        <div class="mr-2" v-if="isOpen"><img :src="require('@/assets/icons/arrow-up.svg')" style="height: 5px" /></div>
      </div>
    </div>
    <div v-if="isOpen" class="p-5 pb-1 rounded bg-gray-200">
      <div v-for="(filter, f) in sortedFilters" :key="f" @click="selectEmit(filter.value)" class="flex items-center cursor-pointer mb-4 font-brother text-ch1blue text-xs justify-between" :class="{ 'font-base': !filter.selected, 'font-bold': filter.selected }">
        <div class="flex items-center">
          <div class="mr-2" v-if="filter.selected"><img :src="require('@/assets/icons/filter-box-selected.svg')" style="height: 16px" /></div>
          <div class="mr-2" v-if="!filter.selected"><img :src="require('@/assets/icons/filter-box.svg')" style="height: 16px" /></div>
          <div>{{ filter.value }}</div>
        </div>
        <div>{{ filter.count }}</div>
      </div>
    </div>
  </div>
</template>
