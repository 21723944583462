<script setup>
import store from "@/store";
import router from "@/router";
import { computed, onMounted, reactive, ref, defineProps, watch } from "vue";
import Pagination from "@/components/Pagination.vue";
import ImageKit from "@/components/ImageKit.vue";
import Fixed from "@/components/Fixed.vue";
import Overlay from "@/components/Overlay.vue";
import Navigation from "../components/Navigation.vue";
import GalleryFilter from "../components/Gallery/GalleryFilter.vue";
import NftDetailModal from "@/components/NftDetailModal.vue";
import NftListCard from "@/components/NftListCard.vue";
import LoaderComp from "@/components/LoaderComp.vue";
import PaginationDropdown from "@/components/PaginationDropdown.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import GalleryFilterBadge from "@/components/GalleryFilterBadge.vue";
import GalleryFilterGroup from "@/components/Gallery/GalleryFilterGroup.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

const props = defineProps({
  page: { type: [String, Number], default: 1 },
  mine: { type: String, default: "no" },
  filters: { type: String, default: "{}" },
  term: String,
  sort: { type: String, default: "asc" },
});

const list = computed(() => {
  return store.state.gallery.list;
});

const loading = ref(false);

const pageData = reactive({
  tokens: [],
  current_page: props.page,
  total_pages: 1,
  sort_by: "index",
  sort_direction: "asc",
  filters: JSON.parse(props.filters),
  all_filters: [],
  all_filter_keys: [],
  filtered_count: null,
  all_count: null,
  mine: props.mine === "yes" ? "yes" : "no",
});

const isLoggedIn = computed(() => {
  return store.getters["auth/isLoggedIn"];
});

const user = computed(() => {
  return store.state.auth.user;
});

const filterIsSelected = (key, value) => {
  return !!pageData?.filters?.[key]?.find((val) => val === value);
};

const fetchData = async () => {
  window.scrollTo(0, 0);

  loading.value = true;

  // pageData.current_page = page;
  let request, filterReq;

  let mineVar = props.mine === "yes" && isLoggedIn.value ? "yes" : "no";

  try {
    request = await store.dispatch("gallery/getList", {
      page: props.page,
      filters: props.filters,
      mine: mineVar,
      term: props.term,
      sort_by: "index",
      sort_direction: props.sort,
    });

    filterReq = await store.dispatch("gallery/getFilters", {
      // filters: pageData.filters,
      // mine: mineVar,
    });
  } catch (e) {
    console.error(e);
  }

  loading.value = false;

  pageData.all_filters = filterReq.filters;
  pageData.all_filter_keys = filterReq.keys;
  pageData.all_filter_properties = filterReq.properties;
  pageData.filtered_count = request?.total_items;
  pageData.all_count = filterReq.total;
  pageData.tokens = request.tokens;
  pageData.current_page = request.current_page;
  pageData.total_pages = request.total_pages;
  pageData.sort_by = request.sort_by;
  pageData.sort_direction = request.sort_direction;
  pageData.filters = request.filters;

  return;
};

const roundNum = (num) => {
  return Math.round((num + Number.EPSILON) * 10000) / 10000;
  //   return Math.round(num * 100000) / 100000;
};

const toggleMine = () => {
  setMobileOpen(false);
  setEditingNFT(false);

  if (props.mine !== "yes") {
    router.push({
      name: "Gallery",
      query: {
        page: 1,
        mine: "yes",
        filters: props.filters,
        term: searchTerm.value,
        sort: props.sort,
      },
    });
  } else {
    router.push({
      name: "Gallery",
      query: {
        page: 1,
        mine: "no",
        filters: props.filters,
        term: searchTerm.value,
        sort: props.sort,
      },
    });
  }
};

const toggleFilter = (key, value) => {
  setMobileOpen(false);
  setEditingNFT(false);

  if (loading.value) {
    return;
  }

  if (filterIsSelected(key, value)) {
    // is selected
    pageData.filters = {
      ...pageData.filters,
      [key]: [...pageData.filters?.[key]?.filter((val) => val !== value)],
    };
  } else {
    // is not selected
    if (pageData.filters?.[key]) {
      // has a key
      pageData.filters = {
        ...pageData?.filters,
        [key]: [...pageData.filters?.[key], value],
      };
    } else {
      // has no key
      pageData.filters = {
        ...pageData.filters,
        [key]: [value],
      };
    }
  }

  let filtersCopy = { ...pageData.filters };
  let filtersKeys = Object.keys(filtersCopy);
  let filtersClean = {};
  filtersKeys.forEach((k) => {
    if (filtersCopy?.[k]?.length) {
      filtersClean[k] = [...filtersCopy[k]];
    }
  });

  router.push({
    name: "Gallery",
    query: {
      page: 1,
      filters: JSON.stringify(filtersClean),
      mine: props.mine,
      term: searchTerm.value,
      sort: props.sort,
    },
  });
};

const filtersList = computed(() => {
  const keys = Object.keys(pageData.all_filters);

  return keys
    ?.map((k) => {
      let selectedCount = 0;
      let obj = {
        name: k,
        filters: pageData.all_filters[k]?.map((filter) => {
          selectedCount += filterIsSelected(k, filter) ? 1 : 0;
          return {
            value: filter,
            count: pageData.all_filter_properties?.[`${k}-${filter}`],
            selected: filterIsSelected(k, filter),
          };
        }),
      };

      obj.count = selectedCount;

      return obj;
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1));
});

const filterGroups = [
  // {
  //   name: "Eras",
  //   // filterNames: ["1986 World Cup", "90s Yacht Club", "Ancient Rome", "Cyberpunk Los Angeles", "Feudal Japan", "Golden Age of Piracy", "Ice Age", "Mars Base 7", "Medieval Europe", "Society HQ", "Wild West"],
  //   filterNames: ["Era"],
  // },
  {
    name: "Character Type",
    filterNames: ["Citizen", "Competitor", "Nigel", "Steward"],
  },
  {
    name: "Clothes",
    filterNames: [
      "Bottoms",
      "Dress",
      "Eyewear",
      "Footwear",
      "Headwear",
      "Neckwear",
      "Outerwear",
      "Outfit",
      "Top",
    ],
  },
  {
    name: "Body",
    filterNames: ["Beard", "Eyes", "Hairstyle", "Moustache", "Mouth", "Nose", "Scar", "Tattoo"],
  },
  {
    name: "Accessories",
    filterNames: [
      "Accessory (Back Hand)",
      "Accessory (Body)",
      "Accessory (Both Hands)",
      "Accessory (Ears)",
      "Accessory (Front Hand)",
      "Accessory (Mouth)",
      "Accessory (Waist)",
      "Accessory (Wrist)",
    ],
  },
];

const filterGroupsList = computed(() => {
  let filterGroupLists = filterGroups.map((group) => {
    let foundSubfilters = filtersList.value.filter((f) => {
      return !!group.filterNames.find((fn) => {
        return fn === f.name;
      });
    });
    return {
      ...group,
      filters: foundSubfilters,
    };
  });

  let otherFilterGroup = filtersList.value.filter((fil) => {
    let found = false;
    filterGroupLists.forEach((item) => {
      if (item.filters.find((f) => f.name === fil.name)) {
        found = true;
      }
    });
    return !found;
  });

  return [
    ...filterGroupLists,
    {
      name: "Other",
      filters: otherFilterGroup,
    },
  ];
});

const updatePage = (page) => {
  setMobileOpen(false);

  if (loading.value) {
    return;
  }

  router.push({
    name: "Gallery",
    query: {
      page,
      mine: props.mine,
      filters: props.filters,
      term: searchTerm.value,
      sort: props.sort,
    },
  });
};

onMounted(async () => {
  await store.dispatch("auth/getUser");
  await fetchData();
});

watch(isLoggedIn, async (oldVal, newVal) => {
  if (oldVal !== newVal) {
    await fetchData();
  }
});

watch(props, async (oldVal, newVal) => {
  console.info("props changed", { oldVal, newVal });
  await fetchData();
});

const selectedNft = ref(null);
const selectNft = (token) => {
  setEditingNFT(false);
  selectedNft.value = token;
  updateForm.token_id = token?.index;
  updateForm.name = token?.name;
  // commenting out until descriptions can be served by back end
  // updateForm.description = token.description;
};

const calcPercent = (number) => {
  return (Math.round(number * 10000) / 100).toFixed(2);
};

const updateForm = reactive({
  token_id: null,
  name: null,
  description: null,
});

const submitUpdate = async () => {
  const updated = await store.dispatch("gallery/updateToken", updateForm);
  if (updated) {
    setEditingNFT(false);
  }
};

const mobileOpen = ref(false);

const toggleMobileOpen = () => {
  mobileOpen.value = !mobileOpen.value;
};

const setMobileOpen = (op) => {
  mobileOpen.value = op;
};

const editingNFT = ref(false);
const setEditingNFT = (va) => {
  editingNFT.value = va;
};

const searchTerm = ref(props.term);
const doSearch = () => {
  mobileOpen.value = false;
  router.push({
    name: "Gallery",
    query: {
      page: props.page,
      mine: props.mine,
      filters: props.filters,
      term: searchTerm.value,
      sort: props.sort,
    },
  });
};
const clearSearch = () => {
  searchTerm.value = null;
  doSearch();
};

const updateDirection = () => {
  if (pageData.sort_direction === "asc") {
    router.push({
      name: "Gallery",
      query: {
        page: props.page,
        mine: props.mine,
        filters: props.filters,
        term: props.term,
        sort: "desc",
      },
    });
  } else {
    router.push({
      name: "Gallery",
      query: {
        page: props.page,
        mine: props.mine,
        filters: props.filters,
        term: props.term,
        sort: "asc",
      },
    });
  }
};
</script>

<template>
  <div
    class="h-full bg-contain flex items-center justify-center bg-top relative flex-col bg-ch1cream-light"
    :style="{
      'background-image': `url('${require('@/assets/backgrounds/gallery-v3.jpg')}')`,
    }"
  >
    <NavigationNew mode="light" />

    <Breadcrumbs
      :links="[{ routeName: 'Home', label: 'Home' }, { label: 'Gallery' }]"
      class="mt-8"
    />

    <div class="bg-repeat h-full relative min-h-screen w-full p-4 md:px-8 pb-16 pt-0">
      <div class="grid grid-cols-1 lg:grid-cols-5 gap-8 relative">
        <div class="col-span-1 lg:col-span-2 flex-shrink-0 hidden lg:block xl:col-span-1">
          <div class="bg-white rounded-xl p-4 lg:p-8 box-shadow-std">
            <div class="flex items-center mb-6">
              <div
                class="text-ch1blue font-brother font-bold text-3xl 2xl:text-5xl mr-4 hidden lg:block"
              >
                Filter
              </div>
            </div>
            <div class="mb-8">
              <form @submit.prevent="doSearch">
                <div
                  class="rounded border border-gray-300 bg-white w-full flex overflow-hidden items-stretch"
                >
                  <input
                    class="px-2 py-2 flex-grow text-sm"
                    placeholder="Search token # or name"
                    v-model="searchTerm"
                  />
                  <button type="submit" class="bg-ch1blue text-white px-2 font-sans font-medium">
                    GO
                  </button>
                </div>
              </form>
            </div>
            <div v-if="filterGroupsList.length">
              <div v-for="(group, g) in filterGroupsList" :key="g">
                <GalleryFilterGroup
                  :name="group.name"
                  :filters="group.filters"
                  @select="toggleFilter"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-span-1 md:col-span-3 lg:col-span-3 xl:col-span-4 p-4 md:p-8 pb-12 flex-grow bg-white rounded-xl box-shadow-std"
        >
          <div class="flex flex-col lg:flex-row justify-between items-center mb-6">
            <div class="flex items-end mb-4 lg:mb-0">
              <div
                class="text-ch1blue font-brother font-bold text-3xl 2xl:text-5xl mr-4 hidden xl:block"
              >
                Characters
              </div>
              <div
                class="font-brother font-bold mr-8 text-gray-400 text-base 2xl:text-xl"
                v-if="pageData.all_count"
              >
                {{ pageData.filtered_count }} / {{ pageData.all_count }} ({{
                  calcPercent(pageData.filtered_count / pageData.all_count)
                }}%)
              </div>
            </div>
            <div class="flex items-center">
              <div
                class="mr-6 flex items-center cursor-pointer border border-ch1blue rounded-lg py-2 px-4 font-brother font-bold text-ch1blue text-xs md:text-sm"
                @click="updateDirection"
              >
                <div class="mr-2 text-right">
                  <span class="hidden md:inline">Sort: </span
                  >{{ props.sort === "asc" ? "Oldest First" : "Newest First" }}
                </div>
                <div class="flex-shrink-0">
                  <img
                    :src="require('@/assets/icons/sort.svg')"
                    style="height: 15px; color: #ffffff"
                  />
                </div>
              </div>
              <div
                class="flex items-center cursor-pointer border border-ch1blue rounded-lg py-2 px-4 font-brother font-bold text-xs md:text-sm"
                @click="toggleMine"
                :class="{
                  'text-white bg-ch1blue': props.mine === 'yes',
                  'text-ch1blue': props.mine !== 'yes',
                }"
                v-if="isLoggedIn"
              >
                My Characters
              </div>
            </div>
          </div>

          <div
            class="mb-6 flex flex-col items-center lg:items-start"
            v-if="props.mine === 'yes' || props.term || Object.keys(pageData.filters)?.length"
          >
            <div class="flex justify-center flex-wrap">
              <!-- MY CHARACTERS -->
              <GalleryFilterBadge
                v-if="props.mine === 'yes'"
                :action="toggleMine"
                label="My Characters Only"
              />

              <!-- SEARCH TERM -->
              <GalleryFilterBadge
                v-if="props.term"
                :action="clearSearch"
                :label="`Search: ${props.term}`"
              />

              <!-- ALL SELECTED FILTERS -->
              <template v-for="(filterSection, f) in pageData.filters" :key="f">
                <GalleryFilterBadge
                  v-for="(filter, fi) in filterSection"
                  :key="fi"
                  :action="
                    () => {
                      toggleFilter(f, filter);
                    }
                  "
                  :label="`${f}: ${filter}`"
                />
              </template>
            </div>
          </div>
          <div v-if="pageData?.tokens?.length">
            <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
              <NftListCard
                v-for="token in pageData.tokens"
                :key="token.id"
                @click="selectNft(token)"
                :token="token"
              />
            </div>
            <div class="mt-8 flex justify-center">
              <Pagination
                :currentPage="pageData.current_page"
                :totalPages="pageData.total_pages"
                @page="updatePage"
              />
              <!-- <PaginationDropdown :currentPage="pageData.current_page" :totalPages="pageData.total_pages" @page="updatePage" /> -->
            </div>
          </div>
          <div v-else class="text-center py-24 font-maindisplay text-xl bg-gray-200 rounded">
            No tokens
          </div>
          <NftDetailModal
            v-if="selectedNft"
            :token="selectedNft"
            @close="
              () => {
                selectNft(null);
              }
            "
          />
        </div>
      </div>

      <div
        class="w-full fixed lg:hidden bg-white pt-2 pb-2 px-4 max-h-full bottom-0 left-0 right-0 overflow-auto z-filters shadow border-t border-gray-300"
      >
        <div @click="toggleMobileOpen" class="flex items-center cursor-pointer">
          <div class="mr-4">
            <img :src="require('@/assets/icons/filter.svg')" style="height: 10px" />
          </div>
          <div class="font-brother font-bold text-ch1blue">Filters</div>
        </div>
        <div v-if="mobileOpen" class="pt-4">
          <div class="mb-8">
            <form @submit.prevent="doSearch">
              <div
                class="rounded border border-gray-300 bg-white w-full flex overflow-hidden items-stretch"
              >
                <input
                  class="px-2 py-2 flex-grow text-sm"
                  placeholder="Search token # or name"
                  v-model="searchTerm"
                />
                <button type="submit" class="bg-ch1blue text-white px-2">GO</button>
              </div>
            </form>
          </div>
          <!-- <GalleryFilter v-for="(filterSection, f) in filtersList" :key="f" :name="filterSection.name" :filters="filterSection.filters" @select="toggleFilter"></GalleryFilter> -->
          <div v-if="filterGroupsList.length">
            <div v-for="(group, g) in filterGroupsList" :key="g">
              <GalleryFilterGroup
                :name="group.name"
                :filters="group.filters"
                @select="toggleFilter"
              />
              <!-- <div>{{ group.name }}</div>
              <div>
                <GalleryFilter v-for="(filterSection, f) in group.filters" :key="f" :name="filterSection.name" :filters="filterSection.filters" @select="toggleFilter"></GalleryFilter>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoaderComp v-if="loading" />
  </div>
</template>

<style lang="pcss">
.toggle {
  @apply border-2 border-white shadow rounded-full w-16 h-8 relative cursor-pointer bg-gray-100;
}
.toggle-inner {
  left: 2px;
  @apply rounded-full absolute transform transition-all translate-x-0 w-7 h-7 bg-ch1blue flex justify-center items-center flex-col text-xs text-white;
}
.toggle-inner.on {
 @apply translate-x-full bg-green-500;
}
</style>
