<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  reactive,
  ref,
  watch,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
} from "vue";
import store from "@/store";
import Fixed from "./Fixed.vue";
import Overlay from "./Overlay.vue";
import ImageKit from "@/components/ImageKit.vue";
import TokenCropper from "./TokenCropper.vue";
import { saveAs } from "file-saver";
import { gsap } from "gsap";
import Editor from "@toast-ui/editor";
import LoaderComp from "./LoaderComp.vue";
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";

const imagebox = ref(null);
const imageBoxHeight = ref(300);
const loading = ref(false);
const descriptionViewer = ref(null);

const props = defineProps({
  token: Object,
});

const user = computed(() => {
  return store.state.auth.user;
});

const emit = defineEmits(["close", "updated"]);

const updateForm = reactive({
  token_id: props.token?.index,
  name: props.token?.name,
  description: null,
});

const submitUpdate = async () => {
  loading.value = true;
  const updated = await store.dispatch("gallery/updateToken", {
    ...updateForm,
    description: editor.value?.getMarkdown(),
  });
  if (updated) {
    setEditingNFT(false);
  }
  loading.value = false;
};

const descriptionInput = ref(null);
const editingNft = ref(false);
const setEditingNFT = (va) => {
  editingNft.value = va;
};
const defaultDescription =
  "Join the ranks of the Stewards, Competitors, Citizens, and other members of The Society of the Hourglass! Visit the Ice Age, Cyberpunk LA, Mars Base 7, Ancient Rome, and more! These time-traveling characters are the key to your access, influence, and voice in this exciting and innovative web3 entertainment brand.";
const editor = ref(null);
const viewer = ref(null);
const hasCustomDescription = computed(() => {
  return props.token?.description != defaultDescription;
});
const setViewer = () => {
  if (hasCustomDescription.value) {
    setTimeout(() => {
      viewer.value = new Viewer({
        el: descriptionViewer.value,
        height: "600px",
        initialValue: props.token?.description,
      });
    }, 100);
  }
};
watch(editingNft, (newVal, oldVal) => {
  if (newVal) {
    const startingValue = hasCustomDescription.value ? props.token.description : "";

    setTimeout(() => {
      editor.value = new Editor({
        el: descriptionInput.value,
        height: "300px",
        initialEditType: "wysiwyg",
        previewStyle: "tab",
        usageStatistics: false,
        initialValue: startingValue,
        autofocus: !hasCustomDescription.value,
        toolbarItems: [
          ["heading", "bold", "italic", "strike"],
          ["quote", "ul", "ol"],
          ["link", "hr"],
        ],
      });
    }, 1);

    // toolbarItems: [
    //   ['heading', 'bold', 'italic', 'strike'],
    //   ['hr', 'quote'],
    //   ['ul', 'ol', 'task', 'indent', 'outdent'],
    //   ['table', 'image', 'link'],
    //   ['code', 'codeblock'],
    //   ['scrollSync'],
    //   // Using Option: Customize the last button
    //   [{
    //     el: this.createLastButton(),
    //     command: 'bold',
    //     tooltip: 'Custom Bold'
    //   }]
    // ]
  } else {
    setViewer();
  }
});

const calcPercent = (number) => {
  return (Math.round(number * 10000) / 100).toFixed(2);
};

const croppingNft = ref(false);

const downloadNft = () => {
  saveAs(props.token.image, `Society of the Hourglass #${props.token.index}.jpg`);
};

const toggleCrop = () => {
  croppingNft.value = !croppingNft.value;
};

const isOwnedBy = computed(() => {
  return store.getters["auth/tokenOwnedByUser"](props.token);
  // return user.value?.wallet_address === props.token.owner_address;
});

const isMainCollection = computed(() => {
  return props.token.contract_address === "0x116fda1bf1f9c38a98afe2a8fd67ba5b9714dfa9";
});

const modal = ref(null);

const usernameForToken = computed(() => {
  return props.token?.wallet?.username ?? props.token?.owner_address;
});

const cleanName = computed(() => {
  return props.token?.name?.replace("Society of the Hourglass ", "");
});

const eraColors = {
  default: "black",
  "1986 World Cup": "#01DE65",
  "90s Yacht Club": "#B61DF1",
  "Ancient Rome": "#0CAAE1",
  "Cyberpunk Los Angeles": "#064BDC",
  "Feudal Japan": "#0CBE94",
  "Golden Age of Piracy": "#EC946B",
  "Ice Age": "#93BCC5",
  "Mars Base 7": "#E44937",
  "Medieval Europe": "#9DB6A0",
  "Society HQ": "#7090A5",
  "Wild West": "#F2B762",
};

const titleColor = computed(() => {
  let era = props.token?.metadata?.find((t) => t.trait_type === "Era")?.value;
  return eraColors?.[era] ?? eraColors.default;
});

const setInfoBoxHeight = () => {
  if (imagebox.value.offsetWidth > 385) {
    imageBoxHeight.value = `${imagebox.value.offsetWidth - 1}px`;
  } else {
    imageBoxHeight.value = "";
  }
};

onMounted(async () => {
  gsap.fromTo(
    modal.value,
    {
      y: 100,
      opacity: 0,
    },
    {
      duration: 0.3,
      opacity: 1,
      y: 0,
    }
  );

  setInfoBoxHeight();
  window.addEventListener("resize", setInfoBoxHeight);

  setViewer();
});

onUnmounted(() => {
  window.removeEventListener("resize", setInfoBoxHeight);
});
</script>

<template>
  <Fixed>
    <Overlay
      @click="
        () => {
          emit('close');
        }
      "
    >
      <div
        class="relative bg-white rounded-lg m-4 max-h-screen w-full overflow-auto max-w-sm lg:max-w-full xl:max-w-6xl"
        @click.stop="() => {}"
        ref="modal"
      >
        <div class="flex flex-col lg:flex-row lg:items-start">
          <div class="flex justify-center items-center lg:w-2/5 flex-shrink-0" ref="imagebox">
            <div class="relative">
              <TokenCropper
                :token="props.token"
                v-if="croppingNft"
                @cancel="
                  () => {
                    croppingNft = false;
                  }
                "
              />
              <ImageKit :url="props.token.image" width="1000" v-else />
            </div>
          </div>
          <div
            class="relative overflow-auto lg:w-3/5"
            :style="{ 'max-height': `${imageBoxHeight}` }"
          >
            <div class="p-4 relative lg:p-8" v-if="!editingNft">
              <div class="mb-6">
                <div class="font-brother text-xs text-gray-400">
                  Society of the Hourglass <span v-if="isMainCollection">#{{ token.index }}</span>
                </div>
                <div class="font-brother mb-2 flex items-end">
                  <div class="text-3xl font-black" :style="{ color: titleColor }">
                    {{ cleanName }}
                  </div>
                  <div
                    class="cursor-pointer ml-4"
                    @click="setEditingNFT(true)"
                    v-if="isOwnedBy && isMainCollection"
                  >
                    <img :src="require('@/assets/icons/edit-icon.svg')" />
                  </div>
                </div>
                <div class="text-sm mb-4 text-gray-500 truncate font-brother">
                  Owned by:
                  <router-link
                    class="text-blue"
                    :to="{ name: 'UserDetail', params: { wallet: usernameForToken } }"
                    >{{ isOwnedBy ? `you (${usernameForToken})` : usernameForToken }}</router-link
                  >
                </div>
                <div
                  v-if="isMainCollection"
                  class="flex flex-col lg:flex-row items-start lg:items-center"
                >
                  <div
                    v-if="isOwnedBy"
                    @click="toggleCrop"
                    class="flex items-center text-sm cursor-pointer mr-6 text-gray-400 mb-4"
                  >
                    <div class="mr-2">
                      <img :src="require('@/assets/icons/crop-icon.svg')" style="height: 20px" />
                    </div>
                    <div>Create PFP</div>
                  </div>
                  <div
                    @click="downloadNft"
                    class="flex items-center text-sm cursor-pointer mr-6 text-gray-400 mb-4"
                  >
                    <div class="mr-2">
                      <img
                        :src="require('@/assets/icons/download-icon.svg')"
                        style="height: 20px"
                      />
                    </div>
                    <div>Download Full Resolution</div>
                  </div>
                  <a
                    :href="`https://opensea.io/assets/0x116fda1bf1f9c38a98afe2a8fd67ba5b9714dfa9/${props.token.index}`"
                    target="_blank"
                    class="flex items-center text-sm cursor-pointer mr-6 text-gray-400 mb-4"
                  >
                    <div class="mr-2">
                      <img :src="require('@/assets/icons/opensea-icon.svg')" style="height: 20px" />
                    </div>
                    <div>View on OpenSea</div>
                  </a>
                </div>
              </div>
              <div class="mb-10" v-if="isMainCollection">
                <div class="font-brother mb-2 flex items-end">
                  <div class="text-3xl font-black" :style="{ color: titleColor }">Backstory</div>
                  <div
                    class="cursor-pointer ml-4"
                    @click="setEditingNFT(true)"
                    v-if="isOwnedBy && isMainCollection"
                  >
                    <img :src="require('@/assets/icons/edit-icon.svg')" />
                  </div>
                </div>
                <div v-if="hasCustomDescription">
                  <div ref="descriptionViewer" id="descriptionViewer"></div>
                </div>
                <div v-else class="text-xl text-gray-400">
                  This character doesn’t have a backstory yet!
                </div>
              </div>
              <div>
                <div class="text-3xl font-black font-brother mb-4" :style="{ color: titleColor }">
                  Traits
                </div>
                <div class="flex flex-wrap">
                  <div
                    v-for="(data, d) in props.token.metadata_full"
                    :key="d"
                    class="text-xs py-1 px-3 bg-gray-100 text-ch1blue mr-2 mb-2 rounded-full font-brother"
                  >
                    <strong>{{ data.trait_type }}</strong
                    >: {{ data.value }}
                    <span v-if="data.trait_match_percent" class="ml-2 text-gray-400">{{
                      data.trait_match_percent
                        ? `(${calcPercent(data.trait_match_percent)}%)`
                        : null
                    }}</span>
                  </div>
                </div>
                <div class="mt-2 text-gray-400 italic text-xs" v-if="isMainCollection">
                  *percentages shown here are based on the entire collection
                </div>
              </div>
            </div>
            <div v-if="editingNft" class="p-4 lg:p-8 relative">
              <div class="font-maindisplay mb-4">
                Updating Society of the Hourglass #{{ props.token.index }}
              </div>
              <form @submit.prevent="submitUpdate" class="w-full max-w-2xl">
                <div class="mb-4">
                  <label class="text-sm mb-2 text-ch1blue font-bold font-brother">Name</label>
                  <input
                    :placeholder="`Society of the Hourglass #${props.token.index}`"
                    v-model="updateForm.name"
                    class="border border-gray-300 rounded p-2 w-full"
                  />
                </div>
                <div class="mb-6">
                  <label class="text-sm mb-2 text-ch1blue font-bold font-brother"
                    >Description</label
                  >
                  <div ref="descriptionInput" id="descriptionInput"></div>
                </div>
                <div class="flex mt-6">
                  <button
                    type="button"
                    class="btn btn-sm btn-ch1red mr-2"
                    @click="setEditingNFT(false)"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-sm">Submit Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <LoaderComp v-if="loading" />
      </div>
    </Overlay>
  </Fixed>
</template>
